<template>
    <div class="userWithdraw">
        <div class="invite_top">
            <div class="invite_top_title">提现记录</div>
            <div class="invite_top_withdraw">已提现<span>{{info.total_cash||'0.00'}}</span>元</div>
        </div>
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="item_l">
                    <div>{{item.type==1?'支付宝':'微信'}}提现：{{item.price}}元</div>
                </div>
                <div class="item_time">{{item.create_time}}</div>
            </div>
        </div>
        <el-empty
          v-if="!list || list.length <= 0"
          description="暂无数据"
        ></el-empty>
        <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page>
    </div>
  </template>
  
  <script>
  const format = (percentage) => (percentage === 100 ? "Full" : `${percentage}%`);
  // import { WOW } from 'wowjs'
  import page from "../../components/page.vue";
  export default {
    name: "userWithdraw",
    components: {
      page,
    },
    data() {
      return {
        // 学习记录
        list: [],
        // 全部作业
        worklist: [],
  
        // 分页
        total: 0, //总数
        page: 1, //页数
        limit: 12, //每页个数
  
        // 收藏参数
        type: "", //类型
        collId: "", //收藏id
  
        is_benren: 1,
  
            tabindex: 1,

            info:{},
      };
    },
    mounted() {
      this.$parent.tab = 2;
      this.$parent.tabIndex = 9;
      this.$parent.$parent.routerIndex = 0;
      this.$parent.$parent.showIndex = 1;
          this.is_benren = this.$parent.is_benren;
      this.listFun()
    },
    methods: {
        listFun(e) {
            var that = this;
            this.$api.POST(
                this.$face.cash_record_index,
                {
                    page: this.page,
                    limit: this.limit,
                },
                function (res) {
                    that.info = res.data;
                    that.list = res.data.list;
                    that.loading = false;
                }
            );
        },
    },
  };
  </script>
  
  <style scoped>
    .userWithdraw {
        margin-top: 26px;
        height: auto;
        overflow: hidden;
        padding-bottom: 26px;
        
    }

    .invite_top{
        background: #fff;
        border-radius: 10px;
        padding-top: 56px;
        padding-left: 40px;
        padding-right: 54px;
        padding-bottom: 45px;
        margin-bottom: 12px;
    }    
    .invite_top_withdraw{
        font-size: 18px;
        margin: 0;
    }
    .invite_top_withdraw span{
        font-size: 42px;
        line-height: 0.8;
        font-weight: bold;
        color: #6261fe;
        margin: 0 10px;
    }

    .invite_top_title{
        font-size: 24px;
        line-height: 1;
        margin-bottom: 42px;
    }

    .item{
        padding-left: 38px;
        padding-right: 80px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 12px;
    }
    .item_l{
        display: flex;
        align-items: center;
    }
    .item_l div:first-child{
        width: 348px;
        font-size: 18px;
    }
    .item_l div:nth-child(2){
        width: 348px;
        font-size: 21px;
        color: #6261fe;
        font-weight: normal;
    }
    .item_time{
        font-size: 12px;
        color: #999;
    }
  </style>
  